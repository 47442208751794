<template lang="pug">
  span
    div
      app-btn(
        v-if="reservedExam.result && btnResultLabel"
        @click.native="resultShowAndMarkSeen"
        :color="colorStatus"
        border="#007AFF"
        data-test="results-show"
      ) 
        span.result-text-label {{ btnResultLabel }}
    div
      v-tooltip(top max-width="400" :disabled="resultsLoading ? false : true").tooltip
        template(v-slot:activator="{ on }")
          app-btn(
            color="primary"
            @click.native="loadResult"
            
            data-test="results-get"
            :loading="statusLoaded"
            v-if="canLoadData || statusLoaded || statusFailed"
          ).text-capitalize Get result
        span Queued

    v-dialog(v-model="showing" max-width="500" v-if="reservedExam.result" scrollable)
      v-card(data-test="results-info-dialog").exam-info
        v-card-title.headline {{ candidateName }} results:
        v-divider
        v-card-text.exam-info__container
          h3.form-title Main info
          p.text-box
            span.text-box__title Results:
            span.text-box__content {{ reservedExam.result.results }}
          p.text-box
            span.text-box__title Categorie:
            span.text-box__content {{ reservedExam.product_name }}
          h3.form-title Stats
          template(v-if="reservedExam.result.correct_answers !== null")
            p.text-box
              span.text-box__title Resultaten:
              span.text-box__content
                template(v-if="reservedExam.result.correct_answers") {{ reservedExam.result.correct_answers }}
                template(v-else) -
          template(v-else-if="reservedExam.result.correct_answers === null")
            p.text-box
              span.text-box__title Onderdeel 1. Gevaarherkenning:
              span.text-box__content
                template(v-if="reservedExam.result.hazard_perception ") {{ reservedExam.result.hazard_perception }}
                template(v-else) -
            p.text-box
              span.text-box__title Onderdeel 2. Kennis:
              span.text-box__content
                template(v-if="reservedExam.result.knowledge") {{ reservedExam.result.knowledge }}
                template(v-else) -
            p.text-box
              span.text-box__title Onderdeel 3. Inzicht:
              span.text-box__content
                template(v-if="reservedExam.result.insight") {{ reservedExam.result.insight }}
                template(v-else) -
          h3.form-title Mistake:
          p.text-box(v-if="reservedExam.result.mistake")
            span.text-box__content(v-html="reservedExam.result.mistake.replace(/\\n/g, '<br />')")
          p.text-box(v-else) -
        v-card-actions
          v-spacer
          v-btn(text color="primary" @click="hideModal" data-test="results-info-dialog-cancel") close
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import { RESULT_STATUS } from '@/app/admin/modules/results/core/models/resultReservedExam'
import { RESERVED_EXAM_RESULT } from "../../core/courseDay-const"
import AppBtn from '@/components/global/actions/BaseButton.vue'
import { COLOR_STATUS_NEW as COLOR_STATUS } from '../../../results/core/results-const'

export default {
  mixins: [showingMixin, errorsMixin],

  inject: ['svc'],

  props: {
    reservedExam: {
      type: Object,
      required: true
    },

    candidateName: {
      type: String,
      default: 'name'
    },

    isMijnReservation: Boolean
  },

  data: () => ({
    loading: false
  }),

  computed: {
    btnResultLabel() {
      if (!this.reservedExam.result || !this.reservedExam.result.results[0]) return null
      return this.reservedExam.result.results[0].toUpperCase()
    },

    mistake() {
      return null
      if (this.statusInit) return null
      return this.reservedExam.result.mistake.replace(/\n/g, '<br />')
    },

    loadedSeen() {
      if (!this.reservedExam.result) return false
      return this.reservedExam.result.seen
    },

    statusInit() {
      if (!this.reservedExam.result) return true
      return this.canBeLoaded
    },

    canBeLoaded() {
      return this.reservedExam.result === RESERVED_EXAM_RESULT.CAN_BE_LOADED
    },

    canLoadData() {
      if (!this.reservedExam.result || (!this.isMijnReservation && this.reservedExam.result.can_be_loaded)) return true
      return false
    },

    statusLoaded() {
      if (this.reservedExam.result && this.reservedExam.result.status === RESULT_STATUS.FETCHING) return true
      return false
    },

    statusFailed() {
      if (this.reservedExam.result && this.reservedExam.result.status === RESULT_STATUS.FAILED) return true
      return false
    },

    resultsLoading() {
      return false
    },

    colorStatus() {
      if (!this.reservedExam.result) return 'primary'
      if (!this.loadedSeen) return COLOR_STATUS.UNSEEN
      if (this.reservedExam.result && this.reservedExam.ignore_results) return COLOR_STATUS.IGNORE_RESUTLS
      if (this.reservedExam.result && this.reservedExam.result.has_zero_mistakes) return COLOR_STATUS.ZERO_MISTAKES
      if (this.reservedExam.result && this.reservedExam.result.has_passed) return COLOR_STATUS.HAS_PASSED
      if (this.reservedExam.result && this.reservedExam.result.has_not_came) return COLOR_STATUS.HAS_NOT_CAME
  
      if (this.reservedExam.result && !this.reservedExam.result.has_passed) return COLOR_STATUS.NOT_HAS_PASSED
      return null
    },
  },

  methods: {
    async loadResult() {
      try {
        this.loading = true
        await this.svc.loadResult(this.reservedExam.id)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    resultShowAndMarkSeen() {
      this.updateShowing(true)
      this.markSeen()
    },

    async markSeen() {
      try {
        await this.svc.markSeen(this.reservedExam.id)
        this.reservedExam.result.seen = true
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },


    hideModal() {
      this.updateShowing(false)
    },
  },

  components: {
    AppBtn,
  }
}
</script>

<style lang="scss" scoped>
.text-box {
  margin-top: 0;
  margin-bottom: 5px;

  &__title {
    margin-right: 3px;
  }

  &__content {
    color: #000000;
  }
}

.btn-result {
  display: inline-block;
}

.result-text-label {
  color: $new-primary;
}

</style>

<style lang="scss">
.exam-info {
  &__container {
    padding-top: 10px !important;
  }

  .form-title {
    padding-top: 10px;

    &:first-child {
      padding-top: 0;
    }
  }
}

.result-text-label {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #FFFFFF;
  text-align: center;
  font-weight: 700;
  font-family: $font;
  line-height: 16px;
}

</style>
